<!-- 累计销售统计 -->

<template>
	<div class="sales-statistics-dialog">
		<w-dialog ref="dialogRef" title="累计销售统计" width="60%" top="5vh" :hideFooter="true">
			<common-table ref="roleTable" tableHeight="600" :filters="filters" :tableData="tableData"
				:totalNum="totalNum" :columns="tableColumns" @filterCriteria="filterCriteria">
				<template #continuity='{row}'>
					<div class="flex align-center">
						<div class="dots" :class="row.is_continuity_text == '是' ? 'bg-green' : 'bg-orange'"></div>
						<div>{{ row.is_continuity_text }}</div>
					</div>
				</template>

				<!-- 合计 -->
				<template #amount>
					<div class="amount">
						<div class="flex">
							<span class="amount-title">合计</span>
							<span class="number-total">{{ totalDatas.all_number }}</span>
							<span class="money-total">{{ totalDatas.all_money }}</span>
						</div>
					</div>
				</template>
			</common-table>
		</w-dialog>
	</div>
</template>

<script>
	import { ref, nextTick, } from "vue";
	import { Member, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";

	export default {
		emits: ["submit"],
		setup(props, { emit }) {
			const dialogRef = ref(null);  // 弹框对象
			const currentRow = ref('');  // 当前行数据
			const tableData = ref([]);  // 表格数据
			/** 表格对象 */
			const roleTable = ref(null);
			const totalNum = ref(0);  // 总数
			const totalDatas = ref({
				all_number: 0,
				all_money: 0,
			});  // 合计
			/** 筛选条件列表 */
			const filters = ref([
				{
					name: "keywords",
					filterType: "search",
					value: "",
					placeholder: "请输入用户手机号",
					action: "filterCriteria",
				},
				{
					filterType: "select",
					name: "is_continuity",
					value: "",
					placeholder: "请选择是否连续包月",
					options: [{ id: '1', name: '否' }, { id: '2', name: '是' }],
					val: "id",
					lab: "name",
					action: "filterCriteria",
				},
			]);
			/** 表格配置数据 */
			const tableColumns = ref([
				{
					prop: "om_mm_id",
					label: "会员ID",
					showTooltip: true,
					minWidth: 60,
				},
				{
					prop: "m_mobile",
					label: "会员手机号",
					showTooltip: true,
					minWidth: 100,
				},
				{
					prop: "om_month",
					label: "购买数量",
					showTooltip: true,
					minWidth: 60,
				},
				{
					prop: "useRange",
					label: "会员有效期",
					minWidth: 120,
					showTooltip: true,
				},
				{
					prop: "om_money",
					label: "支付金额",
					showTooltip: true,
				},
				{
					prop: "continuity",
					type: "customRender",
					label: "是否连续包月",
					showTooltip: true,
				},
			]);
			/**
			 * 
			 * 打开对话框
			 * 
			 * **/
			const openDialog = (row) => {
				currentRow.value = row;
				dialogRef.value.show();
				getSalesStatisti();
			}
			/**
			 * 
			 * 关闭对话框
			 * 
			 * **/
			const closeDialog = () => {
				dialogRef.value.close();
			}
			/**
			 * 
			 * 打开对话框加载状态
			 * 
			 * **/
			const closeDialogLoading = () => {
				dialogRef.value.isLoading = false;
			}
			/**
			 * 
			 * 获取表格数据
			 * 
			 * **/
			const getSalesStatisti = () => {
				Member.getMonthlyCardOrder({
					mc_id: currentRow.value.mc_id,
					keywords: filters.value[0].value,
					is_continuity: filters.value[1].value,
				}).then((res) => {
					if (res.Code === 200) {
						tableData.value = res.Data.data.data;
						totalNum.value = res.Data.data.total;
						totalDatas.value.all_number = res.Data.count_data.all_number ? res.Data.count_data.all_number : 0;
						totalDatas.value.all_money = res.Data.count_data.all_money ? res.Data.count_data.all_money : 0;
						nextTick(() => {
							roleTable.value.tableLoad();
						})
					} else {
						ElMessage.error(res.Message);
					}
				});
			}
			/**
			 * 
			 * 筛选条件
			 * 
			 * **/
			const filterCriteria = () => {
				getSalesStatisti();
			}
			return {
				openDialog,
				dialogRef,
				closeDialog,
				closeDialogLoading,
				currentRow,
				tableData,
				getSalesStatisti,
				Member,
				tableColumns,
				roleTable,
				filters,
				totalNum,
				filterCriteria,
				totalDatas,
			};
		},
	};
</script>

<style lang="scss">
	.sales-statistics-dialog {

		.amount {
			margin-top: 10px;
			justify-content: space-between;
		}

		.amount-title {
			font-size: 18px;
			color: #1AC994;
			align-items: center;
		}

		.number-total {
			margin-left: 30%;
			color: #1AC994;
		}

		.money-total {
			margin-left: 35%;
			color: #1AC994;
		}

		.el-table__row {
			.el-table__cell {
				padding: 9px 0 !important;
			}
		}
	}
</style>