<!-- 添加月卡 -->

<template>
	<div class="add-monthly-card-dialog">
		<w-dialog ref="dialogRef" title="添加月卡" width="70%" top="5vh" @wConfirm="wConfirm">
			<el-row :gutter="20">
				<el-col :span="7">
					<w-title :title_name="'基本信息'"></w-title>
					<el-form class="add-form" ref="addForm" :model="ruleForm" :rules="rules" labelPosition="top">
						<el-form-item label="月卡名称" prop="mc_name">
							<el-input v-model="ruleForm.mc_name" clearable placeholder="请输入月卡名称"></el-input>
						</el-form-item>

						<el-form-item label="月卡售价" prop="mc_price">
							<el-input v-model="ruleForm.mc_price" clearable placeholder="请输入月卡售价" type="number"
								oninput="if(value<0)value=0">
							</el-input>
						</el-form-item>

						<el-form-item label="新客优惠价" prop="mc_first_price">
							<el-input v-model="ruleForm.mc_first_price" clearable placeholder="请输入新客优惠价" type="number"
								oninput="if(value<0)value=0">
							</el-input>
						</el-form-item>

						<el-form-item label="连续包月售价" prop="mc_continuity_price">
							<el-input v-model="ruleForm.mc_continuity_price" clearable placeholder="请输入连续包月售价"
								type="number" oninput="if(value<0)value=0">
							</el-input>
						</el-form-item>

						<el-form-item label="会员折扣" prop="mc_service_charge_discount">
							<el-input v-model="ruleForm.mc_service_charge_discount" clearable placeholder="请输入服务费折扣"
								type="number" oninput="if(value<0)value=0">
							</el-input>
						</el-form-item>

						<el-form-item label="会员积分" prop="mc_integral_multiple">
							<el-input v-model="ruleForm.mc_integral_multiple" clearable placeholder="请输入积分倍数"
								type="number" oninput="if(value<0)value=0">
							</el-input>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="17">
					<w-title :title_name="'会员优惠券'"></w-title>
					<el-table ref="tableref" :data="memberCouponsTableDatas" stripe style="width: 100%"
						element-loading-text="数据加载中" tooltip-effect="light" @selection-change="memberCouponsChange"
						height='300' :row-key="getRowKeys">
						<el-table-column type="selection" width="55" :reserve-selection="true" />
						<el-table-column prop="c_title" label="优惠券名称" show-overflow-tooltip />
						<el-table-column prop="coupon_type" label="优惠券类型" show-overflow-tooltip>
							<template #default="scope">
								<div class="tag-content"
									:class="scope.row.coupon_type.ct_id == 1 ? 'bg-green' : scope.row.coupon_type.ct_id == 2 ? 'bg-blue' : 'bg-pale-orange'">
									{{ scope.row.coupon_type.ct_name }}
								</div>
							</template>
						</el-table-column>
					</el-table>

					<w-title :title_name="'每月优惠券'"></w-title>
					<el-table ref="tableref" :data="monthlyCouponsTableDatas" stripe style="width: 100%"
						element-loading-text="数据加载中" tooltip-effect="light" height='280'>
						<el-table-column prop="c_title" label="优惠券名称" show-overflow-tooltip />
						<el-table-column prop="coupon_type" label="优惠券类型" show-overflow-tooltip>
							<template #default="scope">
								<div class="tag-content"
									:class="scope.row.ct_id == 1 ? 'bg-green' : scope.row.ct_id == 2 ? 'bg-blue' : 'bg-pale-orange'">
									{{ scope.row.ct_name }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="coupon_type" label="每月可享数量" show-overflow-tooltip>
							<template #default="scope">
								<el-input v-model="scope.row.num" clearable placeholder="请输入">
									<template #append>张</template>
								</el-input>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
		</w-dialog>
	</div>
</template>

<script>
	import { ref, reactive, } from "vue";
	import { Member, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";

	export default {
		emits: ["submit"],
		setup(props, { emit }) {
			const dialogRef = ref(null);  // 弹框对象
			const addForm = ref(null);  // 表单对象
			const ruleForm = ref({  // 表单数据对象
				mc_name: '',  // 月卡名称
				mc_price: '',  // 月卡售价
				mc_first_price: '',  // 新客优惠价
				mc_continuity_price: '',  // 连续包月售价
				mc_service_charge_discount: '',  // 服务费折扣
				mc_integral_multiple: '',  // 积分倍数
			});
			const rules = reactive({  // 表单规则对象
				mc_name: [{ required: true, message: "请输入月卡名称", trigger: "blur", }],
				mc_price: [{ required: true, message: "请输入月卡售价", trigger: "blur", }],
				mc_first_price: [{ required: true, message: "请输入新客优惠价", trigger: "blur", }],
				mc_continuity_price: [{ required: true, message: "请输入连续包月售价", trigger: "blur", }],
			});
			const memberCouponsTableDatas = ref([]);  // 会员优惠券数据
			const monthlyCouponsTableDatas = ref([]);  // 每月优惠券数据
			/**
			 * 
			 * 打开对话框
			 * 
			 * **/
			const openDialog = () => {
				monthlyCouponsTableDatas.value = [];
				ruleForm.value = {
					mc_name: '',  // 月卡名称
					mc_price: '',  // 月卡售价
					mc_first_price: '',  // 新客优惠价
					mc_continuity_price: '',  // 连续包月售价
					mc_service_charge_discount: '',  // 服务费折扣
					mc_integral_multiple: '',  // 积分倍数
				};
				getCouponList();
				dialogRef.value.show();
			}
			/**
			 * 
			 * 关闭对话框
			 * 
			 * **/
			const closeDialog = () => {
				dialogRef.value.close();
			}
			/**
			 * 
			 * 打开对话框加载状态
			 * 
			 * **/
			const closeDialogLoading = () => {
				dialogRef.value.isLoading = false;
			}
			/**
			 * 
			 * 获取优惠券列表
			 * 
			 * **/
			const getCouponList = () => {
				Member.getCouponList().then((res) => {
					if (res.Code === 200) {
						memberCouponsTableDatas.value = res.Data;
					} else {
						ElMessage.error(res.Message);
					}
				});
			}
            /**
             * 
             * 会员优惠券选择唯一id
             * 
             * */
			const getRowKeys = (row) => {
				return row.c_id;
			}
            /**
             * 
             * 会员优惠券选择
             * 
             * */
			const memberCouponsChange = (val) => {
				monthlyCouponsTableDatas.value = [];
				if (val.length > 0) {
					val.forEach(item => {
						monthlyCouponsTableDatas.value.push({
							num: '',
							c_title: item.c_title,
							c_id: item.c_id,
							ct_id: item.coupon_type.ct_id,
							ct_name: item.coupon_type.ct_name,
						});
					})
				}
			}
			const wConfirm = () => {
				addForm.value.validate((valid) => {
					if (valid) {
						// 会员折扣；会员优惠券；会员积分均为非必填；但是前端需要验证至少填了其中一项
						if (!ruleForm.value.mc_service_charge_discount) {
							if (!ruleForm.value.mc_integral_multiple) {
								ElMessage.error('请输入会员折扣或者会员积分！');
								return false;
							}
						}
						if (!ruleForm.value.mc_integral_multiple) {
							if (!ruleForm.value.mc_service_charge_discount) {
								ElMessage.error('请输入会员折扣或者会员积分！');
								return false;
							}
						}
						// 判断是否选择优惠券
						let selectArr = [];
						if (monthlyCouponsTableDatas.value.length > 0) {
							monthlyCouponsTableDatas.value.forEach(item => {
								selectArr.push({
									c_id: item.c_id,
									num: item.num,
								});
							})
						}
						// 判断是否输入数量
						if (selectArr.length > 0) {
							for (let i = 0; i < selectArr.length; i++) {
								if (!selectArr[i].num) {
									if (!selectArr[i].num) {
										ElMessage.error('请输入每月可享数量！');
										return false;
									}
								}
							}
						}
						let params = {
							...ruleForm.value,
							coupon: selectArr
						};
						// console.log("提交", params);
						dialogRef.value.isLoading = true; // 按钮加载中状态
						Member.addMonthlyCard(params).then((res) => {
							dialogRef.value.isLoading = false;
							if (res.Code === 200) {
								ElMessage.success("添加成功！"); // 成功提示
								dialogRef.value.close(); // 关闭弹框
								emit("submit");
							} else {
								ElMessage.error(res.Message);
							}
						});
					}
				});
			}
			return {
				openDialog,
				dialogRef,
				closeDialog,
				closeDialogLoading,
				addForm,
				ruleForm,
				rules,
				getRowKeys,
				memberCouponsTableDatas,
				memberCouponsChange,
				getCouponList,
				monthlyCouponsTableDatas,
				wConfirm,
			};
		},
	};
</script>

<style lang="scss">
	.add-monthly-card-dialog {}
</style>