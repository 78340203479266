<!-- 会员月卡 -->

<template>
	<div class="monthly-card main-cnt">
		<div class="content">
			<common-table ref="roleTable" tableHeight="calc(100vh - 325px)" :apiName="Member.getMonthlyCardList"
				:columns="tableColumns" @statusChange="statusChange" @onDetailsBtn="onDetailsBtn">
				<template #operate>
					<el-button type="primary" @click="onAddBtn" v-if="authData.indexOf('n_q1qozieDirBU3M2KldkR226mq6') != -1">
						<el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
						添加月卡
					</el-button>
				</template>

				<template #sale_num='{row}'>
					<div class="cursor-pointer flex" @click="onSalesStatistics(row)">
						<img src="@/assets/img/vip.png" class="s-m-r" style="width: 24px;height: 24px;" />
						<div>{{ row.sale_num }}</div>
					</div>
				</template>

				<template #sale_money='{row}'>
					<div class="cursor-pointer flex" @click="onSalesStatistics(row)">
						<img src="@/assets/img/money.png" class="s-m-r" style="width: 24px;height: 24px;" />
						<div>{{ row.sale_money ? row.sale_money : '0.00' }}</div>
					</div>
				</template>
			</common-table>
		</div>

		<!-- 添加月卡 -->
		<AddMonthlyCard ref="addDialogRef" @submit="reloadLists"></AddMonthlyCard>

		<!-- 详情 -->
		<MonthlyCardDetails ref="infoDialogRef"></MonthlyCardDetails>

		<!-- 销售统计 -->
		<SalesStatistics ref="statistiRef"></SalesStatistics>
	</div>
</template>

<script setup>
	import { ref, onMounted, computed, watch, } from "vue";
	import { Member, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";
	import { useStore } from "vuex";
	import AddMonthlyCard from "../components/AddMonthlyCard.vue";
	import MonthlyCardDetails from "../components/MonthlyCardDetails.vue";
	import SalesStatistics from "../components/SalesStatistics.vue";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})

	/** 表格对象 */
	const roleTable = ref(null);
	/** 表格配置数据 */
	const tableColumns = ref([
		{
			prop: "mc_name",
			label: "月卡名称",
			showTooltip: true,
		},
		{
			prop: "mc_price",
			label: "月卡售价",
			showTooltip: true,
		},
		{
			prop: "mc_first_price",
			label: "新客优惠价",
			showTooltip: true,
		},
		{
			prop: "mc_continuity_price",
			label: "连续包月售价",
			showTooltip: true,
		},
		{
			prop: "mc_status",
			type: "switch",
			label: "在售状态",
			token: "n_be1CNRzB8q82MOcNOZ06RSYzru",
		},
		{
			prop: "sale_num",
			type: "customRender",
			label: "累计销售",
			showTooltip: true,
		},
		{
			prop: "sale_money",
			type: "customRender",
			label: "累计销售金额",
			showTooltip: true,
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 100,
			bottons: [
				{
					name: "详情",
					action: "onDetailsBtn",
					token: "n_oXGtfDBL5Fnxbpg45Y73Buy4OWIt",
				},
			],
		},
	]);
	const addDialogRef = ref(null);  // 添加月卡对象
	const infoDialogRef = ref(null);  // 详情对象
	const statistiRef = ref(null);  // 统计对象
	/**
	 * 
	 * 添加月卡按钮
	 * 
	 * */
	const onAddBtn = () => {
		addDialogRef.value.openDialog();
	}
	/**
	 * 
	 * 修改当前状态
	 * 
	 * */
	const statusChange = (row) => {
		Member.editMonthlyCardStatus({
			mc_id: row.mc_id,
		}).then((res) => {
			if (res.Code === 200) {
				ElMessage.success("状态修改成功！");
				roleTable.value.tableLoad(); // 重新获取列表数据
			} else {
				ElMessage.error(res.Message);
			}
		});
	};
	/**
	 * 
	 * 重新加载列表数据
	 * 
	 * */
	const reloadLists = () => {
		roleTable.value.tableLoad();
	}
	/**
	 * 
	 * 详情按钮
	 * 
	 * */
	const onDetailsBtn = (row) => {
		infoDialogRef.value.openDialog(row);
	}
	/**
	 * 
	 * 累计销售、累计销售金额
	 * 
	 * */
	const onSalesStatistics = (row) => {
		statistiRef.value.openDialog(row);
	}

	onMounted(() => {
		roleTable.value.tableLoad();
	});
</script>

<style lang="scss">
	.monthly-card {
		font-family: "Source Han Sans CN";

		.content {
			padding: 20px;
		}
	}
</style>