<!-- 月卡详情 -->

<template>
	<div class="monthly-card-info-dialog">
		<w-dialog ref="dialogRef" title="详情" width="50%" top="10vh" :hideFooter="true">
			<w-title :title_name="'基本信息'"></w-title>
			<el-row :gutter="20">
				<el-col :span="12" class="s-m-b">
					<div class="flex">
						<div class="info-title">月卡名称</div>
						<div class="info-content">{{ infoDatas.mc_name }}</div>
					</div>
				</el-col>
				<el-col :span="12" class="s-m-b">
					<div class="flex">
						<div class="info-title">月卡售价</div>
						<div class="info-content">{{ infoDatas.mc_price }}</div>
					</div>
				</el-col>
				<el-col :span="12" class="s-m-b">
					<div class="flex">
						<div class="info-title">新客优惠价</div>
						<div class="info-content">{{ infoDatas.mc_first_price }}</div>
					</div>
				</el-col>
				<el-col :span="12" class="s-m-b">
					<div class="flex">
						<div class="info-title">连续包月售价</div>
						<div class="info-content">{{ infoDatas.mc_continuity_price }}</div>
					</div>
				</el-col>
				<el-col :span="12" class="s-m-b">
					<div class="flex">
						<div class="info-title">会员折扣</div>
						<div class="info-content" v-if="infoDatas.mc_service_charge_discount">{{ Math.round(infoDatas.mc_service_charge_discount) }}折</div>
						<div class="cycle-text" v-else>无</div>
					</div>
				</el-col>
				<el-col :span="12" class="s-m-b">
					<div class="flex">
						<div class="info-title">会员积分</div>
						<div class="info-content" v-if="infoDatas.mc_integral_multiple">{{ infoDatas.mc_integral_multiple }}倍</div>
						<div class="cycle-text" v-else>无</div>
					</div>
				</el-col>
			</el-row>

			<w-title :title_name="'每月优惠券'"></w-title>
			<el-table :data="tableDatas" stripe style="width: 100%" element-loading-text="数据加载中" tooltip-effect="light"
				height='450'>
				<el-table-column prop="c_title" label="优惠券名称" show-overflow-tooltip />
				<el-table-column prop="coupon_type" label="优惠券类型" show-overflow-tooltip>
					<template #default="scope">
						<div class="tag-content"
							:class="scope.row.ct_id == 1 ? 'bg-green' : scope.row.ct_id == 2 ? 'bg-blue' : 'bg-pale-orange'">
							{{ scope.row.ct_name }}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="mcc_num" label="每月可享数量" show-overflow-tooltip />
			</el-table>
		</w-dialog>
	</div>
</template>

<script>
	import { ref } from "vue";
	import { Member, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";

	export default {
		emits: ["submit"],
		setup(props, { emit }) {
			const dialogRef = ref(null);  // 弹框对象
			const currentRow = ref('');  // 当前行数据
			const tableDatas = ref([]);  // 表格数据
			const infoDatas = ref('');  // 详情数据
			/**
			 * 
			 * 打开对话框
			 * 
			 * **/
			const openDialog = (row) => {
				currentRow.value = row;
				getMonthlyCardInfo();
			}
			/**
			 * 
			 * 关闭对话框
			 * 
			 * **/
			const closeDialog = () => {
				dialogRef.value.close();
			}
			/**
			 * 
			 * 打开对话框加载状态
			 * 
			 * **/
			const closeDialogLoading = () => {
				dialogRef.value.isLoading = false;
			}
			/**
			 * 
			 * 获取详情数据
			 * 
			 * **/
			const getMonthlyCardInfo = () => {
				Member.getMonthlyCardInfo({ mc_id: currentRow.value.mc_id }).then((res) => {
					if (res.Code === 200) {
						infoDatas.value = res.Data.data;
						tableDatas.value = res.Data.coupon;
						if (tableDatas.value.length > 0) {
							tableDatas.value.forEach(item => {
								item.c_title = item.coupon.c_title;
								item.ct_name = item.coupon.coupon_type.ct_name;
								item.ct_id = item.coupon.coupon_type.ct_id;
							})
						}
						dialogRef.value.show();
					} else {
						ElMessage.error(res.Message);
					}
				});
			}
			return {
				openDialog,
				dialogRef,
				closeDialog,
				closeDialogLoading,
				currentRow,
				tableDatas,
				infoDatas,
				getMonthlyCardInfo,
			};
		},
	};
</script>

<style lang="scss">
	.monthly-card-info-dialog {
		.info-title {
			color: #92979E;
			text-align: left;
			width: 100px;
		}

		.info-content {
			color: #262C30;
		}

		.el-table__row {
			.el-table__cell {
				padding: 9px 0 !important;
			}
		}
	}
</style>